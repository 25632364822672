import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'
import Loader from '../../components/Loader.jsx'
import { fetchDataFromApi } from '../../lib/AllGlobalFunctions.jsx'
import { setPageTitle } from '../redux/slice/pageTitleSlice.jsx'

const Section1 = React.lazy(() => import('./Section1/Section1.jsx'))
const Section2 = React.lazy(() => import('./Section2/Section2.jsx'))

const Dashboard = () => {
  const dispatch = useDispatch()
  const abortControllerRef = useRef(null)
  const [dashboardData, setDashboardData] = useState({
    productViewCount: {},
    sellerViewCount: {},
    productCount: {},
    sellerCount: {}
  })
  const [loading, setLoading] = useState(false)

  const [filterData, setFilterData] = useImmer({
    reportText: 'All',
    ReportDays: 0,
    SellerId: ''
    // Top: 10,
  })

  const fetchAllData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    const newAbortController = new AbortController()
    abortControllerRef.current = newAbortController

    setLoading(true)

    try {
      let responses

      if (filterData?.SellerId) {
        const productResponse = await Promise.allSettled([
          fetchDataFromApi(
            'SellerDashboard/ProductDetailsCount',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          ),
          fetchDataFromApi(
            'SellerDashboard/SellerVisitCount',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          )
        ])
        responses = [productResponse[0], productResponse[1]]
      } else {
        responses = await Promise.allSettled([
          fetchDataFromApi(
            'SellerDashboard/ProductDetailsCount',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          ),
          fetchDataFromApi(
            'SellerDashboard/SellerVisitCount',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          ),
          fetchDataFromApi(
            'DashboardNew/NewProductsCount',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          ),
          fetchDataFromApi(
            'DashboardNew/getSellerCounts',
            setLoading,
            'GET',
            filterData,
            newAbortController.signal
          ),
          fetchDataFromApi(
            'SellerData',
            setLoading,
            'GET',
            { pageIndex: 0, pageSize: 0 },
            newAbortController.signal
          )
        ])
      }
      setLoading(false)
      if (filterData?.SellerId) {
        setDashboardData({
          ...dashboardData,
          productViewCount: responses[0]?.value?.data?.data,
          sellerViewCount: responses[1]?.value?.data?.data
        })
      } else {
        const [
          productResponse,
          sellerResponse,
          productCountRes,
          sellerCountRes,
          allSellerData
        ] = responses

        setDashboardData({
          sellerViewCount: sellerResponse?.value?.data?.data,
          productViewCount: productResponse?.value?.data?.data,
          sellerCount: sellerCountRes?.value?.data?.data,
          productCount: productCountRes?.value?.data?.data,
          allSellerData: allSellerData?.value?.data?.data
        })
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
    }
  }, [filterData])

  useEffect(() => {
    fetchAllData()
    dispatch(setPageTitle('Dashboard'))
  }, [fetchAllData])

  return (
    <>
      <Row className='gy-3 p-3'>
        <Col md={{ order: 12 }} className='mt-0 d-flex justify-content-end'>
          <Dropdown className='pv-chartdash-dropbtn'>
            <Dropdown.Toggle
              className='btn-light'
              style={{ height: '33px' }}
              id='dropdown-basic'
            >
              {filterData?.reportText ?? 'All'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = 0
                    draft.reportText = 'All'
                  })
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = 1
                    draft.reportText = 'Today'
                  })
                }}
              >
                Today
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -1
                    draft.reportText = 'Yesterday'
                  })
                }}
              >
                Yesterday
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = 7
                    draft.reportText = 'This week'
                  })
                }}
              >
                This Week
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -7
                    draft.reportText = 'Last Week'
                  })
                }}
              >
                Last Week
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = 30
                    draft.reportText = 'This Month'
                  })
                }}
              >
                This Month
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -30
                    draft.reportText = 'Last Month'
                  })
                }}
              >
                Last Month
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -90
                    draft.reportText = 'Last 3 Months'
                  })
                }}
              >
                Last 3 Months
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -180
                    draft.reportText = 'Last 6 Months'
                  })
                }}
              >
                Last 6 Months
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = 365
                    draft.reportText = 'This Year'
                  })
                }}
              >
                This Year
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setFilterData((draft) => {
                    draft.ReportDays = -365
                    draft.reportText = 'Last Year'
                  })
                }}
              >
                Last Year
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Suspense fallback={<Loader />}>
        <Section1
          dashboardData={dashboardData}
          loading={loading}
          filterData={filterData}
          setFilterData={setFilterData}
        />
        <Section2
          loading={loading}
          dashboardData={dashboardData}
          filterData={filterData}
        />
      </Suspense>
    </>
  )
}

export default Dashboard
