import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import { customStyles } from '../../../components/customStyles'
import FormikControl from '../../../components/FormikControl'
import IpCheckbox from '../../../components/IpCheckbox'
import Loader from '../../../components/Loader'
import ModelComponent from '../../../components/Modal'
import TextError from '../../../components/TextError'
import CustomToast from '../../../components/Toast/CustomToast'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import axiosProvider from '../../../lib/AxiosProvider'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import { isAllowPriceVariant } from '../../../lib/GetBaseUrl'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'

const ManageFilter = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [allState, setAllState] = useImmer({
    allAvailableSizes: [],
    allAvailableSpecification: [],
    specification: [],
    sizeValuesDataToFeedInTable: [],
    specValuesData: [],
    availableSizeValues: [],
    dataForModalTable: [],
    specificationTypeValues: [],
    specificationType: [],
    categoryData: []
  })
  const { pageAccess, userInfo } = useSelector((state) => state.user)
  const location = useLocation()
  const [categoryId, setCategoryId] = useState()
  const [initialValues, setInitialValues] = useImmer({
    assignAttributes: {
      categoryID: null,
      isAllowSize: false,
      isAllowColorsInTitle: false,
      isAllowColors: false,
      isAllowColorsInFilter: false,
      isAllowColorsInComparision: false,
      titleSequenceOfColor: ''
    },
    specification: {},
    size: {}
  })
  const [allModals, setAllModals] = useImmer({
    sizeButtonClick: false,
    sizeModal: false,
    specificationModal: false,
    valueButtonClick: false,
    showSize: false,
    showSpecification: false
  })
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const dispatch = useDispatch()

  const fetchDataForButtonClicks = async (endpoint, setterFunc) => {
    setLoading(true)
    const respone = await axiosProvider({
      method: 'GET',
      endpoint
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          return setterFunc(res?.data?.data)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchSpecificationData = async (categoryId = null) => {
    const respone = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSpecificationToCategory/GetById',
      queryString: `?Id=${categoryId ? categoryId : id}`
    })
      .then((res) => {
        if (res.status === 200) {
          setInitialValues((draft) => {
            draft.assignAttributes = res?.data?.data
          })
          setCategoryId(res?.data?.data?.id)
          if (res?.data?.data?.isAllowSize === true) {
            setAllModals((draft) => {
              draft.showSize = true
            })
            fetchSpecificationValues(
              `AssignSizeValuesToCategory/ByAssignSpecId?AssignSpecID=${res?.data?.data?.id}&PageIndex=0&PageSize=0`,
              (data) =>
                setAllState((draft) => {
                  draft.sizeValuesDataToFeedInTable = getUniqueListBy(
                    data,
                    'sizeTypeID'
                  )
                })
            )
          }
          if (res?.data?.data?.isAllowSpecifications === true) {
            setAllModals((draft) => {
              draft.showSpecification = true
            })
            fetchSpecificationValues(
              `AssignSpecValuesToCategory/byAssignSpecID?assignSpecId=${res?.data?.data?.id}&PageIndex=0&PageSize=0`,
              (data) =>
                setAllState((draft) => {
                  draft.specValuesData = getUniqueListBy(data, 'specTypeID')
                })
            )
          }
        }
      })
      .catch((err) => alert(err))
  }
  const handleDelete = async (endpoint, assignSpecID) => {
    setLoading(true)
    await axiosProvider({
      method: 'DELETE',
      endpoint,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchSpecificationData(assignSpecID)
          if (endpoint?.includes('SizeValues')) {
            fetchSpecificationValues(
              `AssignSizeValuesToCategory/ByAssignSpecId?AssignSpecID=${assignSpecID}&PageIndex=0&PageSize=0`,
              (data) =>
                setAllState((draft) => {
                  draft.sizeValuesDataToFeedInTable = getUniqueListBy(
                    data,
                    'sizeTypeID'
                  )
                })
            )

            if (endpoint?.includes('SpecValues')) {
              fetchSpecificationValues(
                `AssignSpecValuesToCategory/byAssignSpecID?assignSpecId=${assignSpecID}&PageIndex=0&PageSize=0`,
                (data) =>
                  setAllState((draft) => {
                    draft.specValuesData = getUniqueListBy(data, 'specTypeID')
                  })
              )
            }
          }
          setLoading(false)
          setToast({
            show: true,
            text: res?.data?.message,
            variation: res?.data?.code !== 200 ? 'error' : 'success'
          })

          setTimeout(() => {
            setToast({ ...toast, show: false })
          }, 2000)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleEditData = async (assignSpecID, sizeTypeID) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSizeValuesToCategory/byAssignSpecId&SizeTypeId',
      queryString: `?assignSpecId=${assignSpecID}&sizeTypeId=${sizeTypeID}&pageIndex=0&pageSize=0`
    })
      .then((res) => {
        if (res?.status === 200) {
          const initVal = res?.data?.data?.length && res?.data?.data[0]
          setAllModals((draft) => {
            draft.sizeModal = !draft.sizeModal
          })

          fetchSpecificationValues(
            `SizeValue/ByParentId?pageindex=0&PageSize=0&ParentId=${sizeTypeID}`,
            (data) => {
              let availableSizeValues =
                data?.length > 0 &&
                data?.map((size) => {
                  const addedSize = res?.data?.data?.find(
                    (data) => data?.sizeId === size?.id
                  )
                  return addedSize
                    ? {
                        ...size,
                        isChecked: true,
                        valueEnabled: addedSize?.valueEnabled
                      }
                    : { ...size, valueEnabled: true }
                })
              setAllState((draft) => {
                draft.availableSizeValues = availableSizeValues
              })
            }
          )
          let getSizeIds = []
          res?.data?.data?.map((data) => {
            getSizeIds.push(data?.sizeId)
          })
          setInitialValues((draft) => {
            draft.size = { ...initVal, sizeId: getSizeIds }
          })
          setAllState((draft) => {
            draft.availableSizeValues = res?.data?.data
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleSpecEditData = async (assignSpecID, specID, specTypeID) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSpecValuesToCategory/bySpecificationTypeValues',
      queryString: `?assignSpecId=${assignSpecID}&specId=${specID}&specTypeId=${specTypeID}&pageIndex=0&pageSize=0`
    })
      .then((res) => {
        if (res?.status === 200) {
          const initVal = res?.data?.data?.length && res?.data?.data[0]
          setAllModals((draft) => {
            draft.specificationModal = !draft.specificationModal
          })

          fetchSpecificationValues(
            `SpecificationTypeValue/getByParentId?parentId=${specTypeID}&pageIndex=0&pageSize=0`,
            (data) => {
              setLoading(false)
              let specificationTypeValues =
                data?.length > 0 &&
                data?.map((spec) => {
                  const addedSpec = res?.data?.data?.find(
                    (data) => data?.specTypeValueID === spec?.id
                  )
                  return addedSpec
                    ? {
                        ...spec,
                        isChecked: true,
                        valueEnabled: addedSpec?.valueEnabled
                      }
                    : { ...spec, valueEnabled: true }
                })
              setAllState((draft) => {
                draft.specificationTypeValues = specificationTypeValues
              })
            }
          )
          let getSpecIds = res?.data?.data
            .filter((spec) => spec?.fieldType?.toLowerCase()?.includes('drop'))
            .map((spec) => spec?.specTypeValueID)
            .filter((id) => id !== null)

          setInitialValues((draft) => {
            draft.specification = {
              ...initVal,
              specTypeValueID: getSpecIds?.length > 0 ? getSpecIds : null
            }
          })
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()]
  }

  const fetchSpecificationValues = async (endpoint, setterFunc) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          return setterFunc(res?.data?.data)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onSubmit = async (values, endpoint) => {
    setLoading(true)
    let method = values?.id || values?.id === 0 ? 'PUT' : 'POST'
    await axiosProvider({
      method,
      endpoint,
      data: values,
      location: location.pathname,
      oldData: initialValues,
      userId: userInfo?.userId
    })
      .then((res) => {
        if (res.status === 200) {
          let id = endpoint.includes('AssignSpecificationToCategory')
            ? !values?.id
              ? res?.data?.data
              : categoryId
            : categoryId
          id && fetchSpecificationData(id)
          if (endpoint.includes('AssignSpecificationToCategory')) {
            !values?.id && setCategoryId(res?.data?.data)
            if (res.data.code === 200) {
              setInitialValues((draft) => {
                draft.assignAttributes = {
                  ...values,
                  id: values?.id ? values?.id : res?.data?.data
                }
              })
              if (values?.isAllowSize) {
                setAllModals((draft) => {
                  draft.showSize = true
                })
                values?.id
                  ? fetchSpecificationValues(
                      `AssignSizeValuesToCategory/ByAssignSpecId?AssignSpecID=${values?.id}&PageIndex=0&PageSize=0`,
                      (data) =>
                        setAllState((draft) => {
                          draft.sizeValuesDataToFeedInTable = getUniqueListBy(
                            data,
                            'sizeTypeID'
                          )
                        })
                    )
                  : fetchSpecificationValues(
                      `AssignSizeValuesToCategory/ByAssignSpecId?AssignSpecID=${res?.data?.data}&PageIndex=0&PageSize=0`,
                      (data) =>
                        setAllState((draft) => {
                          draft.sizeValuesDataToFeedInTable = getUniqueListBy(
                            data,
                            'sizeTypeID'
                          )
                        })
                    )
              } else {
                setAllModals((draft) => {
                  draft.showSize = false
                })
                setAllState((draft) => {
                  draft.sizeValuesDataToFeedInTable = []
                })
              }

              if (values?.isAllowSpecifications) {
                setAllModals((draft) => {
                  draft.showSpecification = true
                })
                values?.id
                  ? fetchSpecificationValues(
                      `AssignSpecValuesToCategory/byAssignSpecID?assignSpecId=${values?.id}&PageIndex=0&PageSize=0`,
                      (data) =>
                        setAllState((draft) => {
                          draft.specValuesData = getUniqueListBy(
                            data,
                            'specTypeID'
                          )
                        })
                    )
                  : fetchSpecificationValues(
                      `AssignSpecValuesToCategory/byAssignSpecID?assignSpecId=${res?.data?.data}&PageIndex=0&PageSize=0`,
                      (data) =>
                        setAllState((draft) => {
                          draft.specValuesData = getUniqueListBy(
                            data,
                            'specTypeID'
                          )
                        })
                    )
              } else {
                setAllModals((draft) => {
                  draft.showSpecification = false
                })
                setAllState((draft) => {
                  draft.specValuesData = []
                })
              }
            }
          }
          if (endpoint.includes('AssignSizeValuesToCategory')) {
            setAllModals((draft) => {
              draft.sizeModal = !draft.sizeModal
            })
            fetchSpecificationValues(
              `AssignSizeValuesToCategory/ByAssignSpecId?AssignSpecID=${values?.assignSpecID}&PageIndex=0&PageSize=0`,
              (data) =>
                setAllState((draft) => {
                  draft.sizeValuesDataToFeedInTable = getUniqueListBy(
                    data,
                    'sizeTypeID'
                  )
                })
            )
          }
          if (endpoint.includes('AssignSpecValuesToCategory')) {
            setAllModals((draft) => {
              draft.specificationModal = !draft.specificationModal
            })
            fetchSpecificationValues(
              `AssignSpecValuesToCategory/byAssignSpecID?assignSpecId=${values?.assignSpecID}&PageIndex=0&PageSize=0`,
              (data) =>
                setAllState((draft) => {
                  draft.specValuesData = getUniqueListBy(data, 'specTypeID')
                })
            )
          }
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(setPageTitle('Assign Attributes'))
  }, [])

  useEffect(() => {
    !id &&
      fetchSpecificationValues(
        `MainCategory/getEndCategory?pageindex=0&PageSize=0`,
        (data) =>
          setAllState((draft) => {
            draft.categoryData = data
          })
      )
    if (id && !allModals?.sizeModal && !allModals?.specificationModal) {
      fetchSpecificationData()
    }
  }, [])

  const validationSchema = allModals.specificationModal
    ? Yup.object().shape({
        specID: Yup.string().required('Please select specification'),
        specTypeID: Yup.string().when('specID', {
          is: (value) => value,
          then: () => Yup.string().required('Please select specification type'),
          otherwise: () => Yup.string().notRequired()
        }),
        specTypeValueID: Yup.string().when('fieldType', {
          is: (value) => value === 'DropdownList',
          then: () => Yup.array().min(1, 'Please select atleast one item'),
          otherwise: () => Yup.array().nullable()
        }),
        titleSequenceOfSpecification: Yup.string().when('isAllowSpecInTitle', {
          is: true,
          then: () =>
            Yup.number()
              .min(1, 'Title sequence must be greater than 0')
              .required('Title sequence required'),
          otherwise: () => Yup.string().nullable()
        })
      })
    : allModals?.sizeModal
    ? Yup.object().shape({
        sizeTypeID: Yup.string().required('Size Type required'),
        sizeId: Yup.string().when('sizeTypeID', {
          is: (value) => value,
          then: () => Yup.array().min(1, 'Atleast one size value required'),
          otherwise: () => Yup.array().nullable()
        }),
        titleSequenceOfSize: Yup.string().when('isAllowSizeInTitle', {
          is: true,
          then: () =>
            Yup.number()
              .min(1, 'Title sequence must be greater than 0')
              .required('Title sequence required'),
          otherwise: () => Yup.string().nullable()
        })
      })
    : Yup.object().shape({
        categoryID: Yup.string().required('Category required'),
        titleSequenceOfColor: Yup.string().when('isAllowColorsInTitle', {
          is: true,
          then: () =>
            Yup.number()
              .min(1, 'Title sequence must be greater than 0')
              .required('Title sequence required'),
          otherwise: () => Yup.string().nullable()
        })
      })

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues?.assignAttributes}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onSubmit(
                {
                  ...values,
                  titleSequenceOfColor: values?.titleSequenceOfColor
                    ? values?.titleSequenceOfColor
                    : 0
                },
                'AssignSpecificationToCategory'
              )
            }}
          >
            {({ values, setFieldValue, resetForm, errors }) => (
              <Form>
                <div className='d-flex align-items-center gap-2 mb-4'>
                  <div className='input-select-wrapper flex-fill'>
                    <label className='form-label required'>
                      Select Category
                    </label>
                    <Select
                      id='categoryID'
                      isClearable={true}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      placeholder='Select category'
                      isDisabled={values?.id ? true : false}
                      value={
                        values?.categoryID && {
                          value: values?.categoryID,
                          label: values?.id
                            ? values?.categoryPathNames
                            : allState?.categoryData?.find(
                                (obj) => obj?.id === values?.categoryID
                              )?.pathNames
                        }
                      }
                      options={
                        allState?.categoryData &&
                        allState?.categoryData?.map(({ id, pathNames }) => ({
                          value: id,
                          label: pathNames
                        }))
                      }
                      onChange={(e) => {
                        resetForm({
                          values: {
                            ...initialValues?.assignAttributes,
                            categoryID: e?.value
                          }
                        })
                      }}
                    />
                    <ErrorMessage name='categoryID' component={TextError} />
                  </div>
                </div>

                <div className='d-flex mb-2 align-items-center gap-4'>
                  <IpCheckbox
                    isDisabled={values?.sizeEnabled === false ? true : false}
                    checked={values?.isAllowSize ? true : false}
                    checkboxLabel={'Size'}
                    checkboxid={'isAllowSize'}
                    value={'isAllowSize'}
                    changeListener={(e) => {
                      setFieldValue('isAllowSize', e?.checked)
                    }}
                  />
                  <IpCheckbox
                    isDisabled={values?.colorEnabled === false ? true : false}
                    checked={values?.isAllowColors ? true : false}
                    checkboxLabel={'Color'}
                    checkboxid={'isAllowColors'}
                    value={'isAllowColors'}
                    changeListener={(e) => {
                      setFieldValue('isAllowColors', e?.checked)
                      setFieldValue('isAllowColorsInTitle', false)
                      setFieldValue('titleSequenceOfColor', 0)
                      setFieldValue('isAllowColorsInFilter', false)
                      setFieldValue('isAllowColorsInComparision', false)
                    }}
                  />
                  <IpCheckbox
                    isDisabled={
                      values?.specificationsEnabled === false ? true : false
                    }
                    checkboxLabel={'Specification'}
                    checkboxid={'specification'}
                    value={'specification'}
                    checked={values?.isAllowSpecifications}
                    changeListener={(e) => {
                      setFieldValue('isAllowSpecifications', e?.checked)
                    }}
                  />
                </div>

                {values?.isAllowSize && isAllowPriceVariant && (
                  <div className='input-group-prepend mb-3'>
                    <span className='input-group-text d-inline-flex'>
                      <span className='form-group-checkbox'>
                        <IpCheckbox
                          isDisabled={
                            values?.priceVariantEnabled === false ? true : false
                          }
                          checkboxLabel={'Has Price Variants'}
                          checkboxid={'isAllowPriceVariant'}
                          value={'isAllowPriceVariant'}
                          checked={values?.isAllowPriceVariant ? true : false}
                          changeListener={(e) => {
                            setFieldValue('isAllowPriceVariant', e?.checked)
                          }}
                        />
                      </span>
                    </span>
                  </div>
                )}

                {values?.isAllowColors ? (
                  <div className='d-flex mb-2 gap-2 flex-wrap'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text d-inline-flex'>
                        <span className='form-group-checkbox'>
                          <IpCheckbox
                            checkboxLabel={'Allow Color In Title'}
                            checkboxid={'isAllowColorsInTitle'}
                            value={'isAllowColorsInTitle'}
                            checked={
                              values?.isAllowColorsInTitle ? true : false
                            }
                            changeListener={(e) => {
                              if (!e?.checked) {
                                setFieldValue('titleSequenceOfColor', 0)
                              }
                              setFieldValue('isAllowColorsInTitle', e?.checked)
                            }}
                          />
                        </span>
                      </span>
                    </div>
                    {values?.isAllowColorsInTitle && (
                      <FormikControl
                        label=''
                        control='input'
                        type='text'
                        name='titleSequenceOfColor'
                        placeholder='Color Sequence '
                        onChange={(e) => {
                          const inputValue = e?.target?.value
                          const isValid = /^[0-9\b]+$/.test(inputValue)
                          const fieldName = e?.target?.name
                          if (isValid || !inputValue)
                            setFieldValue([fieldName], inputValue)
                        }}
                        maxLength={5}
                      />
                    )}

                    <div className='input-group-prepend'>
                      <span className='input-group-text d-inline-flex'>
                        <span className='form-group-checkbox'>
                          <FormikControl
                            control='checkbox'
                            label='Allow Color In Filter'
                            name='isAllowColorsInFilter'
                            changeListener={(e) => {
                              setFieldValue('isAllowColorsInFilter', e?.checked)
                            }}
                          />
                        </span>
                      </span>
                    </div>
                    {/* <div className='input-group-prepend'>
                      <span className='input-group-text d-inline-flex'>
                        <span className='form-group-checkbox'>
                          <FormikControl
                            control='checkbox'
                            label='Allow Color In Comparision'
                            name='isAllowColorsInComparision'
                            changeListener={(e) => {
                              setFieldValue(
                                'isAllowColorsInComparision',
                                e?.checked
                              )
                            }}
                          />
                        </span>
                      </span>
                    </div> */}
                  </div>
                ) : (
                  <></>
                )}
                <div className=''>
                  <Button
                    variant='primary'
                    type='submit'
                    className='fw-semibold'
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      {loading &&
        !allModals?.sizeButtonClick &&
        !allModals?.sizeModal &&
        !allModals?.specificationModal &&
        !allModals?.valueButtonClick && <Loader />}

      {allModals?.showSize && (
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex justify-content-between mb-2 align-items-center'>
              <h4 className='head_h3 mb-3'>Manage Size</h4>
              {checkPageAccess(
                pageAccess,
                allPages?.assignAttributes,
                allCrudNames?.write
              ) && (
                <Button
                  variant='warning'
                  className='d-flex align-items-center gap-2 fw-semibold'
                  onClick={() => {
                    setInitialValues((draft) => {
                      draft.size = {
                        assignSpecID: categoryId,
                        sizeTypeID: null,
                        sizeId: [],
                        isAllowSizeInFilter: false,
                        isAllowSizeInComparision: false,
                        isAllowSizeInTitle: false,
                        titleSequenceOfSize: ''
                      }
                    })
                    fetchSpecificationValues(
                      `SizeType?pageindex=0&PageSize=0`,
                      (data) =>
                        setAllState((draft) => {
                          draft.allAvailableSizes = data
                        })
                    )
                    setAllModals((draft) => {
                      draft.sizeModal = !draft.sizeModal
                    })
                    setAllState((draft) => {
                      draft.availableSizeValues = []
                    })
                  }}
                >
                  <i className='m-icon m-icon--plusblack'></i>
                  Create New Size
                </Button>
              )}
            </div>
            {allState?.sizeValuesDataToFeedInTable?.length > 0 && (
              <Table responsive className='align-middle table-list table-list'>
                <thead>
                  <tr>
                    <th>Size Type</th>
                    <th>Value</th>
                    {checkPageAccess(pageAccess, allPages?.assignAttributes, [
                      allCrudNames?.update,
                      allCrudNames?.delete
                    ]) && <th className='text-center'>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {allState?.sizeValuesDataToFeedInTable &&
                    allState?.sizeValuesDataToFeedInTable?.map((elem) => (
                      <tr key={Math.floor(Math.random() * 1000000)}>
                        <td>{elem.sizeTypeName}</td>
                        <td className='text-center'>
                          <button
                            className='btn btn-ct-size'
                            onClick={() => {
                              fetchDataForButtonClicks(
                                `AssignSizeValuesToCategory/ByAssignSpecID&SizeTypeId?assignSpecID=${elem.assignSpecID}&SizeTypeId=${elem.sizeTypeID}&PageIndex=0&PageSize=0`,
                                (data) => {
                                  setAllState((draft) => {
                                    draft.dataForModalTable = data
                                  })
                                  setAllModals((draft) => {
                                    draft.sizeButtonClick =
                                      !draft.sizeButtonClick
                                  })
                                }
                              )
                            }}
                          >
                            Size
                          </button>
                          {/* {category.isAllowSize ? (
                        <button
                          className='btn btn-ct-size'
                          onClick={() => setSizeModalShow(true)}
                        >
                          Size
                        </button>
                      ) : (
                        <button className='btn btn-outline-secondary disabled'>
                          Size
                        </button>
                      )} */}
                        </td>
                        {checkPageAccess(
                          pageAccess,
                          allPages?.assignAttributes,
                          [allCrudNames?.update, allCrudNames?.delete]
                        ) && (
                          <td className='text-center'>
                            <div className='d-flex gap-2 justify-content-center'>
                              {checkPageAccess(
                                pageAccess,
                                allPages?.assignAttributes,
                                allCrudNames?.update
                              ) && (
                                <span
                                  onClick={() => {
                                    handleEditData(
                                      elem.assignSpecID,
                                      elem.sizeTypeID
                                    )
                                  }}
                                >
                                  <EditIcon bg={'bg'} />
                                </span>
                              )}
                              {checkPageAccess(
                                pageAccess,
                                allPages?.assignAttributes,
                                allCrudNames?.delete
                              ) && (
                                <span
                                  onClick={() => {
                                    Swal.fire({
                                      title: _SwalDelete.title,
                                      text: _SwalDelete.text,
                                      icon: _SwalDelete.icon,
                                      showCancelButton:
                                        _SwalDelete.showCancelButton,
                                      confirmButtonColor:
                                        _SwalDelete.confirmButtonColor,
                                      cancelButtonColor:
                                        _SwalDelete.cancelButtonColor,
                                      confirmButtonText:
                                        _SwalDelete.confirmButtonText,
                                      cancelButtonText:
                                        _SwalDelete.cancelButtonText
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(
                                          `AssignSizeValuesToCategory?SizeTypeID=${elem.sizeTypeID}&AssignSpecID=${elem.assignSpecID}`,
                                          elem.assignSpecID
                                        )
                                      } else if (result.isDenied) {
                                      }
                                    })
                                  }}
                                >
                                  <DeleteIcon bg={'bg'} />
                                </span>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}

      {allModals?.showSpecification && (
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex justify-content-between mb-2 align-items-center'>
              <h4 className='head_h3 mb-3'>Manage Specification</h4>
              {checkPageAccess(
                pageAccess,
                allPages?.assignManageSpecification,
                allCrudNames?.write
              ) && (
                <Button
                  variant='warning'
                  className='d-flex align-items-center gap-2 fw-semibold'
                  onClick={async () => {
                    try {
                      if (!allState?.specification?.length) {
                        const response = await axiosProvider({
                          method: 'GET',
                          endpoint: 'Specification/get?pageIndex=0&pageSize=0'
                        })
                        if (response?.status === 200) {
                          const specResponse = await axiosProvider({
                            method: 'GET',
                            endpoint: `SpecificationType/getByParentId?parentId=${response?.data?.data[0]?.id}&pageSize=0&pageIndex=0`
                          })

                          if (specResponse?.status === 200) {
                            setAllState((draft) => {
                              draft.specification = response?.data?.data
                              draft.specificationType = specResponse?.data?.data
                              draft.specificationTypeValues = []
                            })
                            setInitialValues((draft) => {
                              draft.specification = {
                                assignSpecID: categoryId,
                                specTypeValueID: [],
                                specID: response?.data?.data[0]?.id,
                                specTypeID: null,
                                isAllowSpecInFilter: false,
                                isAllowSpecInVariant: false,
                                isAllowSpecInComparision: false,
                                isAllowSpecInTitle: false,
                                isAllowMultipleSelection: false,
                                titleSequenceOfSpecification: ''
                              }
                            })
                          }
                        }
                      } else {
                        const response = await axiosProvider({
                          method: 'GET',
                          endpoint: `SpecificationType/getByParentId?parentId=${allState?.specification[0]?.id}&pageSize=0&pageIndex=0`
                        })

                        setInitialValues((draft) => {
                          draft.specification = {
                            assignSpecID: categoryId,
                            specTypeValueID: [],
                            specID: allState?.specification[0]?.id,
                            specTypeID: null,
                            isAllowSpecInFilter: false,
                            isAllowSpecInVariant: false,
                            isAllowSpecInComparision: false,
                            isAllowSpecInTitle: false,
                            isAllowMultipleSelection: false,
                            titleSequenceOfSpecification: ''
                          }
                        })

                        if (response?.status === 200) {
                          setAllState((draft) => {
                            draft.specificationType = response?.data?.data
                            draft.specificationTypeValues = []
                          })
                        }
                      }
                    } catch {}

                    setAllModals((draft) => {
                      draft.specificationModal = !draft.specificationModal
                    })
                  }}
                >
                  <i className='m-icon m-icon--plusblack'></i>
                  Create Specification
                </Button>
              )}
            </div>
            {allState?.specValuesData?.length > 0 && (
              <Table responsive hover className='table-list table align-middle'>
                <thead className='align-middle'>
                  <tr>
                    <th className='text-nowrap'>Specification</th>
                    <th className='text-nowrap'>Specification Type</th>
                    <th className='text-nowrap'>Value</th>
                    {checkPageAccess(
                      pageAccess,
                      allPages?.assignManageSpecification,
                      [allCrudNames?.update, allCrudNames?.delete]
                    ) && <th className='text-nowrap text-center'>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {allState?.specValuesData?.map((data) => (
                    <tr key={Math.floor(Math.random() * 1000000)}>
                      <td>{data?.specificationName}</td>
                      <td>{data?.specificationTypeName}</td>
                      <td className='text-center'>
                        <button
                          className='btn btn-ct-size'
                          onClick={() => {
                            fetchDataForButtonClicks(
                              `AssignSpecValuesToCategory/bySpecificationTypeValues?assignSpecId=${data?.assignSpecID}&specId=${data?.specID}&specTypeId=${data?.specTypeID}&pageIndex=0&pageSize=0`,
                              (data) => {
                                setAllModals((draft) => {
                                  draft.valueButtonClick =
                                    !draft.valueButtonClick
                                })
                                setAllState((draft) => {
                                  draft.dataForModalTable = data
                                })
                              }
                            )
                          }}
                        >
                          Specification
                        </button>
                      </td>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.assignManageSpecification,
                        [allCrudNames?.update, allCrudNames?.delete]
                      ) && (
                        <td className='text-center'>
                          <div className='d-flex gap-2 justify-content-center'>
                            {checkPageAccess(
                              pageAccess,
                              allPages?.assignManageSpecification,
                              allCrudNames?.update
                            ) && (
                              <span
                                onClick={() => {
                                  handleSpecEditData(
                                    data.assignSpecID,
                                    data?.specID,
                                    data.specTypeID
                                  )
                                }}
                              >
                                <EditIcon bg={'bg'} />
                              </span>
                            )}
                            {checkPageAccess(
                              pageAccess,
                              allPages?.assignManageSpecification,
                              allCrudNames?.delete
                            ) && (
                              <span
                                onClick={() => {
                                  Swal.fire({
                                    title: _SwalDelete.title,
                                    text: _SwalDelete.text,
                                    icon: _SwalDelete.icon,
                                    showCancelButton:
                                      _SwalDelete.showCancelButton,
                                    confirmButtonColor:
                                      _SwalDelete.confirmButtonColor,
                                    cancelButtonColor:
                                      _SwalDelete.cancelButtonColor,
                                    confirmButtonText:
                                      _SwalDelete.confirmButtonText,
                                    cancelButtonText:
                                      _SwalDelete.cancelButtonText
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(
                                        `AssignSpecValuesToCategory?assignSpecId=${data.assignSpecID}&specId=${data.specID}&specTypeId=${data?.specTypeID}`,
                                        data?.assignSpecID
                                      )
                                    } else if (result.isDenied) {
                                    }
                                  })
                                }}
                              >
                                <DeleteIcon bg={'bg'} />
                              </span>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}
      <ModelComponent
        show={allModals?.sizeButtonClick}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={
          allState?.dataForModalTable?.length > 0
            ? `Size Type: ${allState?.dataForModalTable[0]?.sizeTypeName}`
            : 'Size Type'
        }
        onHide={() => {
          setAllState((draft) => {
            draft.dataForModalTable = []
          })
          setAllModals((draft) => {
            draft.sizeButtonClick = !draft?.sizeButtonClick
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={''}
      >
        <Table responsive className='align-middle table-list'>
          {allState?.dataForModalTable && (
            <tbody key={Math.floor(Math.random() * 1000000)}>
              <tr>
                <td>
                  <th className='text-nowrap'>Size Value</th>
                </td>
                <td>
                  {allState?.dataForModalTable
                    ?.map((item) => item?.sizeName)
                    ?.join(', ')}
                </td>
              </tr>
              <tr>
                <td>
                  <th className='text-nowrap'>Allow As Filter</th>
                </td>
                <td>
                  {allState?.dataForModalTable[0]?.isAllowSizeInFilter
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr>
              <tr>
                <td>
                  <th className='text-nowrap'>Allow As Title</th>
                </td>
                <td>
                  {allState?.dataForModalTable[0]?.isAllowSizeInTitle
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr>
              {allState?.dataForModalTable[0]?.isAllowSizeInTitle && (
                <tr>
                  <td>
                    <th className='text-nowrap'>Size Title Sequence</th>
                  </td>
                  <td>
                    {allState?.dataForModalTable[0]?.titleSequenceOfSize
                      ? allState?.dataForModalTable[0]?.titleSequenceOfSize
                      : 0}
                  </td>
                </tr>
              )}
              {/* <tr>
                <td>
                  <th className='text-nowrap'>Allow As Comparision</th>
                </td>
                <td>
                  {allState?.dataForModalTable[0]?.isAllowSizeInComparision
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr> */}
            </tbody>
          )}
        </Table>
      </ModelComponent>

      <ModelComponent
        show={allModals.sizeModal}
        modalsize='lg'
        modalheaderclass={''}
        modeltitle='Manage Size Attributes'
        onHide={() =>
          setAllModals((draft) => {
            draft.sizeModal = !draft.sizeModal
          })
        }
        btnclosetext=''
        closebtnvariant=''
        backdrop='static'
        formbuttonid='new-size'
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues?.size}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(
              {
                ...values,
                titleSequenceOfSize: values?.titleSequenceOfSize
                  ? values?.titleSequenceOfSize
                  : 0,
                assignSpecID: categoryId
              },
              'AssignSizeValuesToCategory'
            )
          }}
        >
          {({ values, setFieldValue }) => (
            <Form id='new-size'>
              {loading && <Loader />}
              <div className='input-select-wrapper flex-fill mb-2'>
                {values?.id ? (
                  <FormikControl
                    label='Size type'
                    control='input'
                    type='text'
                    disabled
                    name='sizeTypeName'
                    value={values?.sizeTypeName}
                  />
                ) : (
                  <>
                    <Select
                      id='sizeTypeID'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      options={
                        allState?.allAvailableSizes &&
                        allState?.allAvailableSizes?.map(
                          ({ id, typeName }) => ({
                            value: id,
                            label: typeName
                          })
                        )
                      }
                      onChange={(e) => {
                        if (e) {
                          fetchSpecificationValues(
                            `SizeValue/ByParentId?pageindex=0&PageSize=0&ParentId=${e?.value}`,
                            (data) =>
                              setAllState((draft) => {
                                draft.availableSizeValues = data
                              })
                          )
                          setFieldValue('sizeId', [])
                          setFieldValue('sizeTypeID', e?.value)
                        }
                      }}
                      placeholder={'Select Size Type'}
                    />
                    <ErrorMessage name='sizeTypeID' component={TextError} />
                  </>
                )}
              </div>
              <div className='d-flex flex-wrap mb-2 gap-3'>
                {allState?.availableSizeValues &&
                  allState?.availableSizeValues?.map(
                    ({ id, typeName, sizeName, isChecked, valueEnabled }) => (
                      <div className='input-group-prepend' key={id}>
                        <span className='input-group-text d-inline-flex'>
                          <span className='form-group-checkbox'>
                            <IpCheckbox
                              isDisabled={valueEnabled === false ? true : false}
                              checkboxLabel={typeName ?? sizeName}
                              checkboxid={typeName ?? sizeName}
                              checked={isChecked ? true : false}
                              value={typeName ?? sizeName}
                              changeListener={(e) => {
                                let sizeChangeListener =
                                  allState?.availableSizeValues?.map((data) => {
                                    if (data?.id === id) {
                                      return { ...data, isChecked: e?.checked }
                                    } else {
                                      return data
                                    }
                                  })
                                setAllState((draft) => {
                                  draft.availableSizeValues = sizeChangeListener
                                })
                                let sizeIdArr = values.sizeId
                                  ? values.sizeId
                                  : []
                                if (e?.checked) {
                                  if (!sizeIdArr.includes(id)) {
                                    sizeIdArr = [...sizeIdArr, id]
                                  }
                                } else {
                                  if (sizeIdArr.includes(id)) {
                                    sizeIdArr = sizeIdArr.filter(
                                      (x) => x !== id
                                    )
                                  }
                                }
                                setFieldValue('sizeId', sizeIdArr)
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    )
                  )}
                <ErrorMessage name='sizeId' component={TextError} />
              </div>
              <div className='d-flex gap-2 flex-wrap'>
                <div className='input-group-prepend'>
                  <span className='input-group-text d-inline-flex'>
                    <span className='form-group-checkbox'>
                      <IpCheckbox
                        checkboxLabel={'Allow Size In Title'}
                        checkboxid={'isAllowSizeInTitle'}
                        value={'isAllowSizeInTitle'}
                        checked={values?.isAllowSizeInTitle}
                        changeListener={(e) => {
                          setFieldValue('isAllowSizeInTitle', e?.checked)
                        }}
                      />
                    </span>
                  </span>
                </div>

                {values?.isAllowSizeInTitle && (
                  <FormikControl
                    label=''
                    control='input'
                    type='text'
                    name='titleSequenceOfSize'
                    placeholder='Size Sequence'
                    isRequired
                    onChange={(e) => {
                      const inputValue = e?.target?.value
                      const isValid = /^[0-9\b]+$/.test(inputValue)
                      const fieldName = e?.target?.name
                      if (isValid || !inputValue)
                        setFieldValue([fieldName], inputValue)
                    }}
                    maxLength={5}
                  />
                )}
                <div className='input-group-prepend'>
                  <span className='input-group-text d-inline-flex'>
                    <span className='form-group-checkbox'>
                      <IpCheckbox
                        checkboxLabel={'Allow Size In Filter'}
                        checkboxid={'isAllowSizeInFilter'}
                        checked={values?.isAllowSizeInFilter}
                        value={'isAllowSizeInFilter'}
                        changeListener={(e) => {
                          setFieldValue('isAllowSizeInFilter', e?.checked)
                        }}
                      />
                    </span>
                  </span>
                </div>
                {/* <div className='input-group-prepend'>
                  <span className='input-group-text d-inline-flex'>
                    <span className='form-group-checkbox'>
                      <IpCheckbox
                        checkboxLabel={'Allow Size In Comparision'}
                        checkboxid={'isAllowSizeInComparision'}
                        checked={values?.isAllowSizeInComparision}
                        value={'isAllowSizeInComparision'}
                        changeListener={(e) => {
                          setFieldValue('isAllowSizeInComparision', e?.checked)
                        }}
                      />
                    </span>
                  </span>
                </div> */}
              </div>
              {values?.finalValidation && (
                <span className='text-danger'>{values?.finalValidation}</span>
              )}
            </Form>
          )}
        </Formik>
      </ModelComponent>

      <ModelComponent
        show={allModals?.sizeButtonClick}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={
          allState?.dataForModalTable?.length > 0
            ? `${allState?.dataForModalTable[0]?.sizeTypeName} Values`
            : 'Values'
        }
        onHide={() => {
          setAllState((draft) => {
            draft.dataForModalTable = []
          })
          setAllModals((draft) => {
            draft.sizeButtonClick = !draft?.sizeButtonClick
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={''}
      >
        <Table responsive hover className='align-middle table-list'>
          <thead>
            <tr>
              <th>Values</th>
              <th>In Filter</th>
              <th>In Title</th>
              <th>Seq.</th>
              {/* <th>In Comparision</th> */}
            </tr>
          </thead>
          <tbody>
            {allState?.dataForModalTable &&
              allState?.dataForModalTable?.map((elem) => (
                <tr key={Math.floor(Math.random() * 1000000)}>
                  <td>{elem?.sizeName}</td>
                  <td>{elem?.isAllowSizeInFilter ? 'Yes' : 'No'}</td>
                  <td>{elem?.isAllowSizeInTitle ? 'Yes' : 'No'}</td>
                  <td>
                    {elem?.titleSequenceOfSize ? elem?.titleSequenceOfSize : 0}
                  </td>
                  {/* <td>{elem?.isAllowSizeInComparision ? 'Yes' : 'No'}</td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </ModelComponent>

      <ModelComponent
        show={allModals.specificationModal}
        modalsize={'lg'}
        modalheaderclass={''}
        modeltitle={'Manage Specification'}
        onHide={() => {
          setAllModals((draft) => {
            draft.specificationModal = !draft.specificationModal
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'new-size'}
        submitname='Add Specification'
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues?.specification}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(
              {
                ...values,
                titleSequenceOfSpecification:
                  values?.titleSequenceOfSpecification
                    ? values?.titleSequenceOfSpecification
                    : 0,
                assignSpecID: categoryId,
                specTypeValueID:
                  values?.fieldType === 'Textbox' ||
                  values?.fieldType === 'File'
                    ? null
                    : values?.specTypeValueID?.length
                    ? values?.specTypeValueID
                    : []
              },
              'AssignSpecValuesToCategory'
            )
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form id='new-size'>
              {loading && <Loader />}
              <div className='row'>
                {/* <div className='col-6 input-select-wrapper  mb-3'>
                  <label className='form-label required'>
                    Select Specification
                  </label>
                  <Select
                    styles={customStyles}
                    isDisabled={values?.id ? true : false}
                    id='specID'
                    name='specID'
                    placeholder='Specification Name'
                    menuPortalTarget={document.body}
                    value={
                      values?.specID && {
                        label: values?.specificationName,
                        value: values?.specID
                      }
                    }
                    options={allState?.specification?.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                    onChange={(e) => {
                      fetchSpecificationValues(
                        `SpecificationType/getByParentId?parentId=${e?.value}&pageSize=0&pageIndex=0`,
                        (data) =>
                          setAllState((draft) => {
                            draft.specificationType = data
                            draft.specificationTypeValues = []
                          })
                      )
                      resetForm({
                        values: {
                          ...initialValues.specification,
                          specID: e?.value,
                          specificationName: e?.label
                        }
                      })
                    }}
                  />
                  <ErrorMessage name='specID' component={TextError} />
                </div> */}

                {(values?.id || allState?.specificationType?.length > 0) && (
                  <div className='col-6 input-select-wrapper  mb-3'>
                    <label className='form-label required'>
                      Select Specification Type
                    </label>
                    <Select
                      styles={customStyles}
                      isDisabled={values?.id ? true : false}
                      id='SpecificationType'
                      placeholder='Select Specification Type'
                      menuPortalTarget={document.body}
                      value={
                        values?.specTypeID && {
                          label: values?.specificationTypeName,
                          value: values?.specTypeID
                        }
                      }
                      options={allState?.specificationType?.map(
                        ({ id, name, fieldType }) => ({
                          value: id,
                          label: name,
                          fieldType
                        })
                      )}
                      onChange={(e) => {
                        setAllState((draft) => {
                          draft.specificationTypeValues = null
                        })
                        e?.value &&
                          e?.fieldType?.toLowerCase() === 'dropdownlist' &&
                          fetchSpecificationValues(
                            `SpecificationTypeValue/getByParentId?parentId=${e?.value}&pageIndex=0&pageSize=0`,
                            (data) => {
                              setAllState((draft) => {
                                draft.specificationTypeValues = data
                              })
                            }
                          )

                        e?.fieldType?.toLowerCase() === 'textbox'
                          ? setFieldValue('specTypeValueID', null)
                          : setFieldValue('specTypeValueID', [])
                        setFieldValue('specTypeID', e?.value)
                        setFieldValue('specificationTypeName', e?.label)
                        setFieldValue('fieldType', e?.fieldType)
                      }}
                    />
                    <ErrorMessage name='specTypeID' component={TextError} />
                  </div>
                )}
                {allState?.specificationTypeValues?.length > 0 && (
                  <div className='mb-4'>
                    <label className='form-label required'>
                      Add Specification Value
                    </label>
                    <div className='d-flex flex-wrap mb-2 gap-3'>
                      {allState?.specificationTypeValues?.map(
                        ({ id, name, isChecked, valueEnabled }) => (
                          <div className='input-group-prepend' key={id}>
                            <span className='input-group-text d-inline-flex'>
                              <span className='form-group-checkbox'>
                                <IpCheckbox
                                  isDisabled={
                                    valueEnabled === false ? true : false
                                  }
                                  checkboxLabel={name}
                                  checkboxid={name}
                                  checked={isChecked ? true : false}
                                  value={name}
                                  changeListener={(e) => {
                                    let specChangeListener =
                                      allState?.specificationTypeValues?.map(
                                        (data) => {
                                          if (data?.id === id) {
                                            return {
                                              ...data,
                                              isChecked: e?.checked
                                            }
                                          } else {
                                            return data
                                          }
                                        }
                                      )
                                    setAllState((draft) => {
                                      draft.specificationTypeValues =
                                        specChangeListener
                                    })
                                    let specIdArr = values?.specTypeValueID
                                      ? values?.specTypeValueID
                                      : []
                                    if (e?.checked) {
                                      if (!specIdArr.includes(id)) {
                                        specIdArr = [...specIdArr, id]
                                      }
                                    } else {
                                      if (specIdArr.includes(id)) {
                                        specIdArr = specIdArr.filter(
                                          (x) => x !== id
                                        )
                                      }
                                    }
                                    setFieldValue('specTypeValueID', specIdArr)
                                  }}
                                />
                              </span>
                            </span>
                          </div>
                        )
                      )}
                      <ErrorMessage
                        name='specTypeValueID'
                        component={TextError}
                      />
                    </div>
                  </div>
                )}

                {values?.specTypeID && (
                  <>
                    {values?.fieldType?.toLowerCase() === 'dropdownlist' && (
                      <>
                        <IpCheckbox
                          checkboxLabel={'Is Allow as Filter?'}
                          checkboxid={'isAllowSpecInFilter'}
                          value={'Allow as Filter'}
                          checked={values?.isAllowSpecInFilter}
                          changeListener={(e) => {
                            setFieldValue('isAllowSpecInFilter', e?.checked)
                          }}
                        />
                        <IpCheckbox
                          checkboxLabel={'Allow Multiple Selection?'}
                          checkboxid={'isAllowMultipleSelection'}
                          checked={values?.isAllowMultipleSelection}
                          value={'Allow Multiple Selection'}
                          changeListener={(e) => {
                            setFieldValue(
                              'isAllowMultipleSelection',
                              e?.checked
                            )
                          }}
                        />
                        <IpCheckbox
                          checkboxLabel={'Allow In Title?'}
                          checkboxid={'isAllowSpecInTitle'}
                          checked={values?.isAllowSpecInTitle}
                          value={'Allow In Title'}
                          changeListener={(e) => {
                            setFieldValue('isAllowSpecInTitle', e?.checked)
                          }}
                        />
                        {values?.isAllowSpecInTitle && (
                          <div className='col-md-3'>
                            <FormikControl
                              label='Title Sequence'
                              control='input'
                              isRequired
                              type='text'
                              name='titleSequenceOfSpecification'
                              value={values?.titleSequenceOfSpecification}
                              placeholder='Color Sequence'
                              onChange={(e) => {
                                const fieldName = e?.target?.name
                                const inputValue = e?.target?.value
                                const isValid = /^[0-9\b]+$/.test(inputValue)
                                if (isValid || !inputValue)
                                  setFieldValue(fieldName, inputValue)
                              }}
                              maxLength={5}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {/* <IpCheckbox
                      checkboxLabel={'Allow as Comparision?'}
                      checkboxid={'isAllowSpecInComparision'}
                      value={'isAllowSpecInComparision'}
                      checked={values?.isAllowSpecInComparision}
                      changeListener={(e) => {
                        setFieldValue('isAllowSpecInComparision', e?.checked)
                      }}
                    /> */}
                  </>
                )}

                {values?.finalValidation && (
                  <span className='text-danger'>{values?.finalValidation}</span>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      <ModelComponent
        show={allModals?.valueButtonClick}
        modalsize={'lg'}
        modalheaderclass={''}
        modeltitle={
          allState?.dataForModalTable?.length > 0
            ? `${allState?.dataForModalTable[0]?.specificationTypeName} Specification Values`
            : 'Specification Values'
        }
        onHide={() => {
          setAllModals((draft) => {
            draft.valueButtonClick = !draft?.valueButtonClick
          })
          setAllState((draft) => {
            draft.dataForModalTable = []
          })
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
      >
        <Table responsive hover className='align-middle table-list'>
          <thead>
            <tr>
              <th>Values</th>
              <th>In Filter</th>
              <th>In Title</th>
              <th>Seq.</th>
              {/* <th>In Comparision</th> */}
              <th>In Multiple Selection</th>
            </tr>
          </thead>
          <tbody>
            {allState?.dataForModalTable?.length > 0 &&
              allState?.dataForModalTable?.map((elem) => (
                <tr key={Math.floor(Math.random() * 1000000)}>
                  <td
                    className={
                      elem?.specificationTypeValueName ? '' : 'text-center'
                    }
                  >
                    {elem?.specificationTypeValueName
                      ? elem?.specificationTypeValueName
                      : '-'}
                  </td>
                  <td>{elem?.isAllowSpecInFilter ? 'Yes' : 'No'}</td>
                  <td>{elem?.isAllowSpecInTitle ? 'Yes' : 'No'}</td>
                  <td>
                    {elem?.titleSequenceOfSpecification
                      ? elem?.titleSequenceOfSpecification
                      : 0}
                  </td>
                  {/* <td>{elem?.isAllowSpecInComparision ? 'Yes' : 'No'}</td> */}
                  <td>{elem?.isAllowMultipleSelection ? 'Yes' : 'No'}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ModelComponent>
    </>
  )
}

export default ManageFilter
